import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            {/* <li className="has-droupdown"><Link to="#">­Home</Link>
                <ul className="submenu">
                    <li><Link to="/business-consulting">Business Consulting</Link></li>
                    <li><Link to="/business-consulting-2">Business Consulting 02</Link></li>
                    <li><Link to="/corporate">Corporate</Link></li>
                    <li><Link to="/business">Business</Link></li>
                    <li><Link to="/digital-agency">Digital Agency</Link></li>
                    <li><Link to="/finance">Finance</Link></li>
                    <li><Link to="/company">Company</Link></li>
                    <li><Link to="/marketing">Marketing Agency</Link></li>
                    <li><Link to="/travel-agency">Travel Agency</Link></li>
                    <li><Link to="/consulting">Consulting</Link></li>
                    <li><Link to="/seo-agency">SEO Agency</Link></li>
                    <li><Link to="/personal-portfolio">Personal Portfolio</Link></li>
                    <li><Link to="/event-conference">Event Conference</Link></li>
                    <li><Link to="/creative-portfolio">Creative Portfolio</Link></li>
                    <li><Link to="/freelancer">Freelancer</Link></li>
                    <li><Link to="/international-consulting">International Consulting</Link></li>
                    <li><Link to="/startup">Startup</Link></li>
                    <li><Link to="/web-agency">Web Agency</Link></li>
                </ul>
            </li> */}
            
            <li className="has-droupdown"><Link to="#">關於我們</Link>
                <ul className="submenu">
                    <li><Link to="/about-us">集團簡介</Link></li>
                    <li><Link to="/members">集團成員</Link></li>
                </ul>
            </li>
            

            <li><Link to="/onlinetrading">網上交易</Link></li>

            <li className="has-droupdown"><Link to="#">客戶服務</Link>
                <ul className="submenu">
                    <li><Link to="/open-acc">開戶手續</Link></li>
                    <li><Link to="/transfer">資金存取</Link></li>
                    
                </ul>
            </li>

            <li><Link to="/charges">服務收費</Link></li>
            <li><Link to="/circular">最新通告</Link></li>
            {/* <li><Link to="/CIES">投資移民</Link></li> */}

            <li><Link to="/contact-us">聯繫我們</Link></li>
        </ul>
    )
}
export default Nav;
